var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "3xl"
    }
  }, [_vm._v(" Edit Klien ")]), _c('ClientForms', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "is-edit": true,
      "mt": "30px"
    },
    model: {
      value: _vm.client,
      callback: function callback($$v) {
        _vm.client = $$v;
      },
      expression: "client"
    }
  }), _c('c-stack', {
    attrs: {
      "mt": "30px",
      "is-inline": "",
      "spacing": "20px"
    }
  }, [_c('c-button', {
    attrs: {
      "type": "button",
      "variant-color": "gray",
      "color": "primary.400",
      "rounded": "full",
      "h": "62px",
      "w": "290px",
      "as": "router-link",
      "to": {
        name: 'superadmin.clients-detail',
        params: _vm.$route.params
      }
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "type": "button",
      "rounded": "full",
      "variant-color": "primary",
      "h": "62px",
      "w": "290px",
      "is-disabled": _vm.isSubmitting
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit_();
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }